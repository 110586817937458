import { Collapse } from 'react-bootstrap';
import React, { Component } from 'react';
import { buttonIcons, dropdownIcons } from '../../../../misc/icons';
import { createToggleFunctions } from '../common';
import 'assets/css/table.css';


function renderAddedType(typeProp = 'type', str = '') {
  const type = this.props.later[typeProp];
  if (!type) { return <p>{`This function has no specified ${str ? str + ' ' : ''}type.`}</p>; }
  const style = { fontFamily: 'monospace', whiteSpace: 'pre' };
  return <p style={style}>{type}</p>;
}


function renderChangedType(typeProp = 'type', str = '') {
  const { earlierVersion, laterVersion, earlier, later } = this.props;
  const eType = earlier[typeProp]|| '';
  const lType = later[typeProp] || '';

  if (!eType && !lType) {
    return (
      <p>{`This function has no ${str ? str + ' ' : ''}type in either version.`}</p>
    );
  }

  const className = lType !== eType ? 'changed' : '';

  return (
    <div>
      <table className='dropdown-table'>
        <thead>
          <tr>
            <th>{`Version ${earlierVersion}`}</th>
            <th className='left-boundary'>{`Version ${laterVersion}`}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className={className}>{ eType }</span></td>
            <td className='left-boundary'><span className={className}>{ lType }</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}


function renderNoArguments() {
  if (this.props.type === 'added') {
    return <p>This function has no arguments.</p>;
  }
  return <p>This function has no arguments in either version.</p>;
}


function renderAddedArgumentsTable() {
  const later = this.props.later;

  return (
    <table className='dropdown-table'>
      <thead>
        <tr>
          <th></th>
          <th className='left-boundary'>Name</th>
          <th>Default value</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        {
          later.arguments
            .map((arg, i) => {
              return (
                <tr key={i}>
                  <th>{i+1}</th>
                  <td className='left-boundary'>{arg.name}</td>
                  <td>{arg.value}</td>
                  <td>{arg.type}</td>
                </tr>
              );
            })
        }
      </tbody>
    </table>
  );
}


function renderChangedArgumentsTable() {
  const { earlier, later, earlierVersion, laterVersion } = this.props;
  
  const eArgs = earlier.arguments;
  const lArgs = later.arguments;
  const n = Math.max(eArgs.length, lArgs.length);
  const combinedArgs = [];

  for (let i = 0; i < n; i++) {
    const earlierName = eArgs[i]?.name || '';
    const earlierValue = eArgs[i]?.value || '';
    const earlierType = eArgs[i]?.type || '';
    const laterName = lArgs[i]?.name || '';
    const laterValue = lArgs[i]?.value || '';
    const laterType = lArgs[i]?.type || '';
    combinedArgs.push({ earlierName, earlierValue, earlierType, laterName, laterValue, laterType });
  }

  return (
    <div>
      <table className='dropdown-table'>
        <thead>
          <tr>
            <th></th>
            <th colSpan="3" className='left-boundary'>{`Version ${earlierVersion}`}</th>
            <th colSpan="3" className='left-boundary'>{`Version ${laterVersion}`}</th>
          </tr>
          <tr>
            <th></th>
            <th className='left-boundary'>Name</th>
            <th>Default value</th>
            <th>Type</th>
            <th className='left-boundary'>Name</th>
            <th>Default value</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {
            combinedArgs
              .map(({ earlierName, earlierValue, earlierType, laterName, laterValue, laterType }, i) => {
                const nameClass = laterName !== earlierName ? 'changed' : '';
                const defaultClass = laterValue !== earlierValue ? 'changed' : '';
                const typeClass = laterType !== earlierType ? 'changed' : '';
                return (
                  <tr key={i}>
                    <th>{i+1}</th>
                    <td className="left-boundary"><span className={nameClass}>{earlierName}</span></td>
                    <td><span className={defaultClass}>{earlierValue}</span></td>
                    <td><span className={typeClass}>{earlierType}</span></td>
                    <td className="left-boundary"><span className={nameClass}>{laterName}</span></td>
                    <td><span className={defaultClass}>{laterValue}</span></td>
                    <td><span className={typeClass}>{laterType}</span></td>
                  </tr>
                );
              })
          }
        </tbody>
      </table>
    </div>
  );
}


function renderAddedDropdown() {
  const later = this.props.later;
  const returnTypeRenderer = renderAddedType.bind(this, 'return_type', 'return');
  const argumentsRenderer = (later.arguments.length ? renderAddedArgumentsTable : renderNoArguments).bind(this);

  if (this.props.isMethod) {
    return (
      <div>
        <h5>Type</h5>
        { renderAddedType.call(this) }
        <h5>Return Type</h5>
        { returnTypeRenderer() }
        <h5>Arguments</h5>
        { argumentsRenderer() }
      </div>
    );
  }

  return (
    <div>
      <h5>Return Type</h5>
      { returnTypeRenderer() }
      <h5>Arguments</h5>
      { argumentsRenderer() }
    </div>
  );
}


function renderChangedDropdown() {
  const { earlier, later } = this.props;
  const returnTypeRenderer = renderChangedType.bind(this, 'return_type', 'return');
  const haveArguments = earlier.arguments.length || later.arguments.length;
  const argumentsRenderer = (haveArguments ? renderChangedArgumentsTable : renderNoArguments).bind(this); 

  if (this.props.isMethod) {
    return (
      <div>
        <h5>Type</h5>
        { renderChangedType.call(this) }
        <h5>Return Type</h5>
        { returnTypeRenderer() }
        <h5>Arguments</h5>
        { argumentsRenderer() }
      </div>
    );
  }

  return (
    <div>
      <h5>Return Type</h5>
      { returnTypeRenderer() }
      <h5>Arguments</h5>
      { argumentsRenderer() }
    </div>
  );
}


function renderDetailsText() {
  const icon = this.state.open ? dropdownIcons.open : dropdownIcons.closed;

  return (
    <div>
      <span>Details <i className={icon} /></span>
    </div>
  );
}


function renderCollapse() {
  const { open } = this.state;

  const renderDropdown = this.props.type === 'added' ? renderAddedDropdown : renderChangedDropdown;

  return (
    <div style={{width: '100%'}}>
      <Collapse in={open} style={{overflow: 'auto'}}>
        { renderDropdown.call(this) }
      </Collapse>
    </div>
  );
}


class FunctionEntry extends Component {
  state = {
    open: this.props.open
  };

  static defaultProps = {
    open: false,
  };

  render() {
    const { type, moduleName, functionName } = this.props;
    const haveDropdown = type !== 'removed';
    const classes = `entry alert flex-row ${haveDropdown ? 'expandable' : 'unexpandable'}`;
    const { onClick, onKeyDown } = createToggleFunctions(this, haveDropdown);
    
    return (
      <div
        data-type={type}
        className={classes}
        role="listitem"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <i className={`type-symbol ${buttonIcons[type]}`}></i>
        <div className="entry-name"><span className="module-name">{moduleName}.</span><wbr/><span>{functionName}</span></div>
        { haveDropdown ? renderDetailsText.call(this) : null }
        { haveDropdown ? renderCollapse.call(this) : null }
      </div>
    );
  }
}


export default FunctionEntry;