import React, { Component, createRef, Fragment } from 'react';
import { Card, Collapse } from 'react-bootstrap';
import Markdown from 'react-markdown';
import { ExpandCardWidget, KeyboardShortcutWidget } from '../../Widgets';
import { currentPackage } from '../../../../datastore';
import { common } from '../common';


function createNewsItem(version, data) {
  if (!data[0]) { return null; }

  const str = data.reduce(function(output, entry) {
    if (entry.category) { output.push(`#### ${entry.category}`); }
    output.push(entry.text);
    return output;
  }, []).join('\n\n');

  return [version, str];
}


class NewsComponent extends Component {
  constructor(props) {
    super(props);
    this.headerRef = createRef();
    this.toggleSectionHandlers = common.createToggleSectionHandlers.call(this);
    this.onKeyDown = this.props.createOnShiftKeyDown.call(this);
    this.focus = () => this.headerRef?.current.focus();
  }

  state = {
    open: true,
    HeaderHover: false
  };

  render() {
    const { earlierVersion, laterVersion, sectionIndex } = this.props;

    const news = currentPackage.getNews(earlierVersion, laterVersion)
      .map(({version, news}) => createNewsItem(version, news))
      .filter(d => d);

    const n = news.length;

    return (
      <Fragment>
        <Card onKeyDown={this.onKeyDown}>
          <Card.Header
            className='section-header'
            onClick={this.toggleSectionHandlers.onClick}
            onKeyDown={this.toggleSectionHandlers.onKeyDown}
            tabIndex="0"
            ref={this.headerRef}
          >
            <Card.Title as="h4">News <KeyboardShortcutWidget shortcutKey={sectionIndex} /><ExpandCardWidget open={this.state.open}/></Card.Title>
          </Card.Header>
          <Collapse in={this.state.open}>
            <Card.Body>
              <div id="news-scroller" className="scrollable-content">
                <div className="accordion">
                  {
                    news.map(([keyName, md]) => {
                      return (
                        <details className="accordion-item" key={keyName} open={n === 1}>
                          <summary className="accordion-header">{keyName}</summary>
                          <Markdown children={md} />
                        </details>
                      );
                    })
                  }
                </div>
              </div>
            </Card.Body>
          </Collapse>
        </Card>
      </Fragment >
    );
  }
}


export default NewsComponent;
