import { Collapse } from 'react-bootstrap';
import React, { Component } from 'react';
import { buttonIcons, dropdownIcons } from '../../../../misc/icons';
import { createToggleFunctions } from '../common';
import { currentPackage, packageExists } from '../../../../datastore';
import 'assets/css/table.css';


function haveMultipleSpecifiers(...arr) {
  return arr.some(a => a?.specifiers.length > 1); 
}


function getSpecifiers(arr) {
  return arr?.specifiers.slice() || [];
}


function coalesceSpecifiers(earlier, later) {
  const map = new Map(
    getSpecifiers(earlier)
      .map(({marker, version}, index) => [marker, {marker, earlier: version, later: '', index}])
  );

  getSpecifiers(later)
    .forEach(function({marker, version}) {
      if (map.has(marker)) { map.get(marker).later = version; }
      else { map.set(marker, {marker, earlier: '', later: version, index: map.size}); }
    });

  return Array.from(map.values())
    .sort(function(a, b) {
      const ae = a.earlier ? 2 : 0;
      const al = a.later ? 1 : 0;
      const be = b.earlier ? 2 : 0;
      const bl = b.later ? 1 : 0;
      const aScore = ae + al;
      const bScore = be + bl;
      if (aScore === bScore) { return a.index - b.index;  }
      return bScore - aScore;
    });
}


function renderAddedDropdown() {
  const dependencyName = this.props.name;
  const packageName = currentPackage.name;
  const laterVersion = this.props.laterVersion;
  const spec = getSpecifiers(this.props.later);

  return (
    <div>
      <table className='dropdown-table'>
        <thead>
          <tr>
            <th>Environment</th>
            <th className='left-boundary'> { `${packageName} ${laterVersion}` }</th>
          </tr>
        </thead>
        <tbody>
          {
            spec.map(function({ marker, version }, i) {
              return (
                <tr key={i}>
                  <td><span>{ marker || '<None>' }</span></td>
                  <td className='left-boundary'><span>{ `${dependencyName} ${version}` }</span></td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}


function renderChangedDropdown() {
  const { earlier, later, earlierVersion, laterVersion } = this.props;
  const dependencyName = this.props.name;
  const packageName = currentPackage.name;
  const spec = coalesceSpecifiers(earlier, later);

  return (
    <div>
      <table className='dropdown-table'>
        <thead>
          <tr>
            <th>Environment</th>
            <th className='left-boundary'> { `${packageName} ${earlierVersion}` } </th>
            <th> { `${packageName} ${laterVersion}` } </th>
          </tr>
        </thead>
        <tbody>
          {
            spec
              .map(({ marker, earlier, later }, i) => {
                const specClass = later !== earlier ? 'changed' : '';
                return (
                  <tr key={i}>
                    <td><span>{ marker || '<None>' }</span></td>
                    <td className="left-boundary"><span className={specClass}>{ earlier ? `${dependencyName} ${earlier}` : '' }</span></td>
                    <td><span className={specClass}>{ later ?  `${dependencyName} ${later}` : '' }</span></td>
                  </tr>
                );
              })
          }
        </tbody>
      </table>
    </div>
  );
}


function renderRightText(haveDropdown) {
  if (haveDropdown) {
    const icon = this.state.open ? dropdownIcons.open : dropdownIcons.closed;

    return (
      <div>
        <span>Details <i className={icon} /></span>
      </div>
    );
  }

  const { type, earlier, later } = this.props;
  const lVersion = later.specifiers[0].version || 'Any version';

  let text;
  if (type === 'added') { text = lVersion; }
  else {
    const eVersion = earlier.specifiers[0].version || 'Any version';
    text = `${eVersion} changes to ${lVersion}`;
  }

  return (
    <div>
      <span>{text}</span>
    </div>
  );  
}


function renderCollapse() {
  const { open } = this.state;

  const renderDropdown = this.props.type === 'added' ? renderAddedDropdown : renderChangedDropdown;

  return (
    <div style={{width: '100%'}}>
      <Collapse in={open} style={{overflow: 'auto'}}>
        { renderDropdown.call(this) }
      </Collapse>
    </div>
  );
}


function renderPackageLink() {
  const { name } = this.props;
  if (!packageExists(name, 'python')) { return null; }

  const onClick = function(evt) {
    evt.stopPropagation();
  };

  const onKeyDown = function(evt) {
    if (['Enter', 'Space'].includes(evt.code)) {
      evt.stopPropagation();
    }
  };

  return (
    <a
      aria-label={name}
      className="linked-package"
      href={`/python/${name}`}
      target="_blank"
      onClick={onClick}
      onKeyDown={onKeyDown}
      rel="noreferrer"
    ><i className="fas fa-link"></i>
    </a>
  );
}


class DependencyEntry extends Component {
  state = {
    open: this.props.open
  };

  static defaultProps = {
    open: false,
  };

  render() {
    const { type, name, earlier, later } = this.props;
    const haveDropdown = type !== 'removed' && haveMultipleSpecifiers(earlier, later);
    const classes = `entry alert flex-row ${haveDropdown ? 'expandable' : 'unexpandable'}`;
    const { onClick, onKeyDown } = createToggleFunctions(this, haveDropdown);
    
    return (
      <div
        data-type={type}
        className={classes}
        role="listitem"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <i className={`type-symbol ${buttonIcons[type]}`}></i>
        <div className='entry-name'><span>{name}</span>{renderPackageLink.call(this)}</div>
        { type !== 'removed' ? renderRightText.call(this, haveDropdown) : null }
        { haveDropdown ? renderCollapse.call(this) : null }
      </div>
    );
  }
}


export default DependencyEntry;