import React, { Component } from 'react';
import { Entry } from '../../ListItems';
import { FilterButtonGroup, SearchBar } from '../../Controls';
import { ResultsWidget } from '../../Widgets';
import { common } from '../common';


const areEqual = (e, l) => e.version === l.version;


class DependenciesComponent extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = common.createButtonClickHandler.call(this);
    const diffify = common.createDiffifyer.call(this, this.props.property, areEqual);
    const diff = diffify();
    this.textFilter = common.createTextFilter.call(this, diff);
    this.buttonFilter = common.createButtonFilter.call(this);
    this.buttonGenerator = common.createButtonGenerator.call(this);
  }
  

  state = {
    filter: '',
    open: true,
    ...common.createButtonClickState()
  };


  render() {
    const textFilteredDiff = this.textFilter();
    const finalDiff = this.buttonFilter(textFilteredDiff);
    const isPackage = this.props.name !== 'Depends';

    return (
      <div className="margin-top-vh">
        <SearchBar
          name={this.props.name}
          searchQuery={this.state.filter}
          setSearchQuery={v => this.setState({ filter: v })}
        />
        <div className="button-row flex-row">
          <FilterButtonGroup
            buttons={this.buttonGenerator()}
            data={textFilteredDiff}
            handler={this.clickHandler}
          />
          <ResultsWidget
            totalCount={textFilteredDiff.length}
            filteredCount={finalDiff.length}
          />
        </div>
        <div className="scrollable-content" role="list">
          {
            finalDiff.map((d) => {
              const type = d.type;
              let rightText = '';
              if (type === 'added') {
                rightText = d.later.version || 'Any version';
              }
              else if (type === 'changed') {
                const from = d.earlier.version || 'Any version';
                const to = d.later.version || 'any version';
                rightText = `${from} changes to ${to}`;
              }
              return (
                <Entry
                  key={d.name}
                  type={type}
                  name={d.name}
                  rightText={rightText}
                  isPackage={isPackage}
                />
              );
            })
          }
        </div>

      </div>
    );
  }
}


export default DependenciesComponent;
