const baseUrl = 'https://diffify.com';
const badgeUrl = 'https://diffify.com/diffify-badge.svg';


function createGetBadge(packageName, language) {
  const url = `${baseUrl}/${language}/${packageName}`;
  const anchor = document.createElement('a');
  anchor.setAttribute('href', url);
  anchor.setAttribute('target', '_blank');

  const badge = document.createElement('img');
  badge.setAttribute('src', badgeUrl);
  badge.setAttribute('alt', `The diffify page for the ${language} package ${packageName}`);
  badge.style.width = '67px';
  badge.style.maxWidth = '100%';

  anchor.appendChild(badge);

  return async function(evt) {
    evt.preventDefault();

    try {
      await navigator.clipboard.writeText(anchor.outerHTML);
      alert('A code snippet has been copied to your clipboard. You can paste this into an HTML or Markdown document.');
    }
    catch(e) {
      alert('Sorry! Could not copy to clipboard');
    };
  };
}


export { createGetBadge };