import { getDatetimeParts } from 'misc/utils';


const origin = 'https://data.diffify.com/';

const baseUrls = {
  'R': `${origin}r-packages/cran/`,
  'python': `${origin}python-packages/pypi/`,
};

let globalCache = { R: {}, python: {} };
let currentPackage;


async function fetchItem(url) {
  const options = { cache: 'no-cache' };
  const response = await fetch(url, options);
  const obj = await response.json();
  return obj;
}

async function fetchItems(urls) {
  const arr = await Promise.all(urls.map(u => fetchItem(u)));
  return arr;
}


async function resetCache(timestamp, language) {
  const base = baseUrls[language];
  const url = `${base}packages.json`;
  const recentUrl = `${base}recent-packages-versions.json`;
  const [packageNames, rawRecentPackagesList] = await fetchItems([url, recentUrl]);
  packageNames.sort((a, b) => a.localeCompare(b));

  // Chronological order
  rawRecentPackagesList.sort((a, b) => new Date(a.date) - new Date(b.date));

  const recentPackagesList = Array
    .from(
      // Remove duplicates, keeping only the latest entry
      new Map(rawRecentPackagesList.map(d => [d.package, d])).values()
    )
    // Reverse chronological order
    .sort((a, b) => new Date(b.date) - new Date(a.date));
  
  globalCache[language] = { timestamp, packageNames, recentPackagesList, packages: {} };
}


async function revalidateCache(language) {
  const url = `${baseUrls[language]}timestamps.json`;
  const data = await fetchItem(url);
  const timestamp = data.latest_update;

  if (timestamp !== globalCache?.[language].timestamp) {
    await resetCache(timestamp, language);
  }
}


function getTimestamp(language) {
  return getDatetimeParts(globalCache[language].timestamp);
}


function getRecentPackagesList(language) {
  return globalCache[language].recentPackagesList;
}


function getCanonicalPackageName(packageName, language) {
  const name = packageName?.toLowerCase();
  return globalCache[language]?.packageNames?.find(d => d.toLowerCase() === name);
}


function packageExists(packageName, language) {
  return getCanonicalPackageName(packageName, language) !== undefined;
}


function getPackageNames(language) {
  return globalCache[language].packageNames;
}


async function createCacheForRPackage(packageName) {
  const packageUrl = `${baseUrls.R}${packageName}`;
  const packageVersionsUrl = `${packageUrl}/versions.json`;
  const newsUrl = `${packageUrl}/news.json`;
  const items = [packageVersionsUrl, newsUrl];
  const [packageVersions, rawNews] = await Promise.all(items.map(u => fetchItem(u)));
  packageVersions.push('empty');

  globalCache.R[packageName] = {};
  const cache = globalCache.R[packageName];
  cache.name = packageName;
  cache.packageVersions = Object.freeze(packageVersions);
  cache.loadedVersions = { empty: {} };
  cache.packageUrl = packageUrl;

  cache.news = rawNews.reduce(function(obj, d) {
    obj[d.version] = d;
    return obj;
  }, {});
}


async function createCacheForPythonPackage(packageName) {
  const packageUrl = `${baseUrls.python}${packageName}`;
  const packageVersionsUrl = `${packageUrl}/versions.json`;
  const packageVersions = await fetchItem(packageVersionsUrl);
  packageVersions.push('empty');

  globalCache.python[packageName] = {};
  const cache = globalCache.python[packageName];
  cache.name = packageName;
  cache.packageVersions = Object.freeze(packageVersions);
  cache.loadedVersions = { empty: {} };
  cache.packageUrl = packageUrl;
}


async function createCacheForPackage(packageName, language) {
  if (language === 'R') { await createCacheForRPackage(packageName); }
  else if (language === 'python') { await createCacheForPythonPackage(packageName); }
}


async function setCurrentPackage(packageName, language) {
  if (!globalCache[language][packageName]) {
    await createCacheForPackage(packageName, language);
  }
  const cache = globalCache[language][packageName];

  const loadVersion = async function(packageVersion) {
    if (cache.loadedVersions[packageVersion]) { return cache.loadedVersions[packageVersion]; }
    const url = `${cache.packageUrl}/${packageVersion}.json`;
    const version = await fetchItem(url);
    cache.loadedVersions[packageVersion] = version;
    return version;
  };

  const getNews = function(earlierVersion, laterVersion) {
    if (language === 'python') { return []; }
    const newsItems = [];
    let started = false;
    for (const packageVersion of cache.packageVersions) {
      if (packageVersion === earlierVersion) { break; }
      if (packageVersion === laterVersion) { started = true; }
      if (started && cache.news[packageVersion]) {
        newsItems.push(cache.news[packageVersion]);
      }
    };
    return newsItems;
  };

  currentPackage = {
    language,
    get name() { return cache.name; },
    get packageVersions() { return cache.packageVersions.slice(); },
    get loadedVersions() { return Object.keys(cache.loadedVersions); },
    getNews,
    loadVersion,
    version: function(v) { return cache.loadedVersions[v]; },
    versionIsLoaded: function(v) { return !!cache.loadedVersions[v]; }
  };
}


export {
  revalidateCache,
  getTimestamp,
  getRecentPackagesList,
  getPackageNames,
  getCanonicalPackageName,
  packageExists,
  setCurrentPackage,
  currentPackage,
};
