/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Diff from 'views/Diff';
import Home from 'views/Home';
import { languageIcons } from 'misc/icons';


const routes = [
  {
    path: '',
    name: 'Home',
    icon: 'fas fa-home',
    component: Home,
    layout: '/',
    get active() {
      return window.location.pathname === '/';
    },
    to: '/'
  },
  {
    path: 'R',
    name: 'R',
    icon: languageIcons.R,
    component: Diff,
    layout: '/',
    _to: '/R',
    get active() {
      return window.location.pathname.startsWith('/R');
    },
    get to() {
      if (this.active) {
        this._to = window.location.pathname;
      }
      return this._to;
    } 
  },
  {
    path: 'python',
    name: 'python',
    icon: languageIcons.python,
    component: Diff,
    layout: '/',
    _to: '/python',
    get active() {
      return window.location.pathname.startsWith('/python');
    },
    get to() {
      if (this.active) {
        this._to = window.location.pathname;
      }
      return this._to;
    } 
  }
];

export default routes;
