import React, { Component, Fragment } from 'react';
import {
  Form
} from 'react-bootstrap';

import 'assets/css/autocomplete.css';
class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: this.props.placeholder ? this.props.placeholder : '',
    };
    this.selectedRef = React.createRef();
  }

  scrollToElement = function() {
    this.selectedRef.current?.scrollIntoView(
      { behavior: 'instant', block: 'nearest', inline: 'start' }
    );
  };

  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    const exactMatch = [];
    const startMatch = [];
    const anywhereMatch = [];
    
    const input = userInput.trim().toLowerCase();

    for(const suggestion of suggestions) {
      const s = suggestion.toLowerCase();
      if (s === input) { exactMatch.push(suggestion); }
      else if (s.startsWith(input)) { startMatch.push(suggestion); }
      else if (s.includes(input)) { anywhereMatch.push(s); }
    }

    const filteredSuggestions = [...exactMatch, ...startMatch, ...anywhereMatch];

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };
  onClick = e => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    this.props.callback(e.currentTarget.innerText);
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    const suggestion = filteredSuggestions[activeSuggestion] || this.state.userInput;

    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: suggestion
      });
      this.props.callback(suggestion);
    }
    else if (e.keyCode === 38) { // up arrow
      e.preventDefault();
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 }, this.scrollToElement);
    }
    else if (e.keyCode === 40) { // down arrow
      e.preventDefault();
      if (activeSuggestion + 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 }, this.scrollToElement);
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">

            {
              filteredSuggestions
                .map((suggestion, index) => {
                  // Flag the active suggestion with a class
                  if (index === activeSuggestion) {
                    const className = 'suggestion-active';
                    return (
                      <li ref={this.selectedRef} className={className} key={suggestion} onClick={onClick}>
                        {suggestion}
                      </li>
                    );
                  }
                  return (
                    <li key={suggestion} onClick={onClick}>
                      {suggestion}
                    </li>
                  );
                })
            }
          </ul>
        );
      }
      else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No suggestions available.</em>
          </div>
        );
      }
    }
    return (
      <Fragment>
        <div>
          <Form.Control
            type="text"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
            placeholder="Click here and type..." />
          {/* <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          placeholder="Click here and type..."
        /> */}
          {suggestionsListComponent}
        </div>
      </Fragment>
    );
  }
}

export default Autocomplete;
