import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import DependenciesContainer from './DependenciesContainer';
import FunctionsComponent from './FunctionsComponent';
import ClassesComponent from './ClassesComponent';
import { common } from '../common'; 


class DiffSection extends Component {
  constructor(props) {
    super(props);
    this.sectionRefs = [];
    this.createOnShiftKeyDown = common.createCreateOnShiftKeyDown.call(this);
  }


  render() {
    const { earlierVersion, laterVersion, currentPackage } = this.props;
    const addSectionRef = ref => this.sectionRefs.push(ref);

    return (
      <Row  className="diff-section">
        <Col md="12">
          <DependenciesContainer
            currentPackage={currentPackage}
            earlierVersion={earlierVersion}
            laterVersion={laterVersion}
            ref={addSectionRef}
            createOnShiftKeyDown={this.createOnShiftKeyDown}
            sectionIndex={1}
          />
        </Col>
        <Col md="12">
          <FunctionsComponent
            currentPackage={currentPackage}
            earlierVersion={earlierVersion}
            laterVersion={laterVersion}
            ref={addSectionRef}
            createOnShiftKeyDown={this.createOnShiftKeyDown}
            sectionIndex={2}
          />
        </Col>
        <Col md="12">
          <ClassesComponent
            currentPackage={currentPackage}
            earlierVersion={earlierVersion}
            laterVersion={laterVersion}
            ref={addSectionRef}
            createOnShiftKeyDown={this.createOnShiftKeyDown}
            sectionIndex={3}
          />
        </Col>
      </Row>
    );
  }
}


export default DiffSection;
