import React, { Component } from 'react';


class KeyboardShortcutWidget extends Component {
  render() {
    return (
      <span className='keyboard-shortcut'>(⇧{this.props.shortcutKey})</span>
    );
  }
}


export default KeyboardShortcutWidget;