import React, { Component } from 'react';
import { Entry } from '../../ListItems';
import { FilterButtonGroup } from '../../Controls';
import ResultsWidget from '../../Widgets/ResultsWidget';
import { common } from '../common';


const buttons = common.defaultButtons.filter(d => d.id !== 'removed');
const accessor = d => [{name: 'python', version: d.python}];
const areEqual = (e, l) => e.version === l.version;


class PythonComponent extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = common.createButtonClickHandler.call(this);
    const diffify = common.createDiffifyer.call(this, accessor, areEqual);
    this.diff = diffify();
    this.buttonFilter = common.createButtonFilter.call(this, buttons);
    this.buttonGenerator = common.createButtonGenerator.call(this, buttons);
  }
  
  state = {
    ...common.createButtonClickState(buttons)
  };

  render() {
    const diff = this.diff;
    const finalDiff = this.buttonFilter(diff);

    return (
      <div className="margin-top-vh">
        <div className="button-row flex-row">
          <FilterButtonGroup
            buttons={this.buttonGenerator()}
            data={diff}
            handler={this.clickHandler}
          />
          <ResultsWidget
            totalCount={diff.length}
            filteredCount={finalDiff.length}
          />
        </div>
        <div className="scrollable-content" role="list">
          {
            finalDiff.map((d) => {
              const type = d.type;
              let rightText;
              if (type === 'added') {
                rightText = d.later.version || 'Any version';
              }
              else if (type === 'changed') {
                const from = d.earlier.version || 'Any version';
                const to = d.later.version || 'any version';
                rightText = `${from} changes to ${to}`;
              }
              return (
                <Entry
                  key={'python'}
                  type={type}
                  name={'Python'}
                  rightText={rightText}
                />
              );
            })
          }
        </div>
      </div>
    );
  }
}


export default PythonComponent;
