import React, { Component, Fragment, createRef } from 'react';
import { Card } from 'react-bootstrap';
import { ViewportList } from 'react-viewport-list';
import { PythonFunctionEntry } from '../../ListItems';
import { FilterButtonGroup, SearchBar } from '../../Controls';
import { ResultsWidget } from '../../Widgets';
import { common } from './../common';



function areEqual(earlier, later) {
  if (earlier.return_type !== later.return_type) { return false; }
  if (earlier.type !== later.type) { return false; }

  const eArgs = earlier.arguments;
  const lArgs = later.arguments;

  if (eArgs.length !== lArgs.length) { return false; }

  return eArgs.every(function({name, value, type}, i) {
    const l = lArgs[i];
    return l.name === name && l.value === value && l.type === type;
  });
}


function getAccessor() {
  const name = this.props.functionName;
  const module = this.props.moduleName;

  return function(dataset) {
    return dataset.classes.find(function(d) {
      return d.module === module && d.name === name;
    })?.methods || [];
  };
}


class MethodsComponent extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = common.createButtonClickHandler.call(this);
    
    const diffify = common.createDiffifyer.call(this, getAccessor.call(this), areEqual);
    const diff = diffify();
    this.textFilter = common.createTextFilter.call(this, diff);
    this.buttonFilter = common.createButtonFilter.call(this);
    this.buttonGenerator = common.createButtonGenerator.call(this);
    
    const openedSet = new Set();
    this.handleOpenAndClose = common.createOpenAndCloseHandler(openedSet);
    this.isOpen = common.createIsOpen(openedSet);

    this.scrollRef = createRef();
    this.toggleSectionHandlers = common.createToggleSectionHandlers.call(this);
  }
  
  state = {
    filter: '',
    ...common.createButtonClickState()
  };

  static defaultProps = {
    module: false,
    count: 0,
    label: '',
    type: 'general',
    handler: null,
  };


  render() {
    const textFilteredDiff = this.textFilter();
    const finalDiff = this.buttonFilter(textFilteredDiff);
    const hideButtons = this.props.type === 'added';

    return (
      <Fragment>
        <Card>
          <Card.Header
            className='section-header'
          >
            <Card.Title as="h4">Methods </Card.Title>
          </Card.Header>
          <Card.Body>
            <SearchBar
              name="Methods"
              searchQuery={this.state.filter}
              setSearchQuery={v => this.setState({ filter: v })}
            />
            <div className="button-row flex-row" style={{height: hideButtons ? '1.5em' : null}}>
              <FilterButtonGroup
                buttons={this.buttonGenerator()}
                data={textFilteredDiff}
                handler={this.clickHandler}
                hidden={hideButtons}
              />
              <ResultsWidget
                totalCount={textFilteredDiff.length}
                filteredCount={finalDiff.length}
              />
            </div>
            <div className="scrollable-content" role="list" ref={this.scrollRef}>
              <ViewportList viewportRef={this.scrollRef} items={finalDiff} overscan={100}>
                {
                  d => {
                    const obj = d.earlier || d.later;  
                    return (
                      <PythonFunctionEntry
                        key={d.name}
                        functionName={obj.name}
                        moduleName={''}
                        earlier={d.earlier}
                        later={d.later}
                        type={d.type}
                        earlierVersion={this.props.earlierVersion}
                        laterVersion={this.props.laterVersion}
                        open={this.isOpen(d.name)}
                        handler={this.handleOpenAndClose}
                        isMethod={true}
                      />
                    );
                  }
                }
              </ViewportList>
            </div>
          </Card.Body>
        </Card>
      </Fragment >
    );
  }
}


export default MethodsComponent;