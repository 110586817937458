import React, { Component } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import FilterButton from './FilterButton';


function clickHandler(prop, value) {
  const handler = this.props.handler;
  if (handler) { handler(prop, value); }
}


class FilterButtonGroup extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = clickHandler.bind(this);
  }

  static defaultProps = {
    buttons: [],
    data: [],
    handler: null,
    accessor: d => d.type,
    hideEmpty: false,
    hidden: false
  };


  render() {
    const { buttons, data, accessor, hideEmpty, hidden } = this.props;

    const counts = data.reduce(function(obj, d) {
      obj[accessor(d)]++;
      return obj;
    }, Object.fromEntries(buttons.map(d => [d.id, 0])));
    
    return (
      <ButtonGroup className="margin-right" size="sm">
        {
          buttons.map(({id, type, label, clicked}) => {
            const count = counts[id];
            return (
              <FilterButton 
                key={id}
                id={id}
                type={type}
                label={label}
                count={count}
                clicked={clicked}
                hide={(!count && hideEmpty) || this.props.hidden}
                handler={v => this.clickHandler(id, v)}
              />
            );
          })
        }
      </ButtonGroup>
    );
  }
}


export default FilterButtonGroup;


