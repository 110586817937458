import React, { Component, Fragment, createRef } from 'react';
import { Card, Collapse } from 'react-bootstrap';
import { ViewportList } from 'react-viewport-list';
import InfoTooltip from '../../../InfoTooltip';
import { PythonClassesEntry } from '../../ListItems';
import { FilterButtonGroup, SearchBar } from '../../Controls';
import { ExpandCardWidget, KeyboardShortcutWidget, ResultsWidget } from '../../Widgets';
import { common } from '../common';


function areEqual(earlier, later) {
  const eMethods = earlier.methods;
  const lMethods = later.methods;
  if (eMethods.length !== lMethods.length) { return false; }

  try {
    const eString = JSON.stringify(eMethods);
    const lString = JSON.stringify(lMethods);
    if (eString === lString) { return true; }
  }
  catch(e) {}

  const eMap = new Map(eMethods.map(d => [d.name, d]));

  return lMethods.every(function(lObj) {
    const eObj = eMap.get(lObj.name);
    if (!eObj) { return false; }
    if (eObj.return_type !== lObj.return_type) { return false; }
    if (eObj.type !== lObj.type) { return false; }
    if (eObj.arguments.length !== lObj.arguments.length) { return false; }
    
    return lObj.arguments.every(function({name, value, type}, i) {
      const ea = eObj.arguments[i];
      return name === ea.name && value === ea.value && type === ea.type;
    });
  });
}


function getName(d) {
  return `${d.module}.${d.name}`;
}


class ClassesComponent extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = common.createButtonClickHandler.call(this);
    const diffify = common.createDiffifyer.call(this, 'classes', areEqual, getName);
    const diff = diffify();
    this.textFilter = common.createTextFilter.call(this, diff);
    this.buttonFilter = common.createButtonFilter.call(this);
    this.buttonGenerator = common.createButtonGenerator.call(this);
    
    const openedSet = new Set();
    this.handleOpenAndClose = common.createOpenAndCloseHandler(openedSet);
    this.isOpen = common.createIsOpen(openedSet);

    this.scrollRef = createRef();
    this.headerRef = createRef();
    this.toggleSectionHandlers = common.createToggleSectionHandlers.call(this);
    this.onKeyDown = this.props.createOnShiftKeyDown.call(this);

    this.focus = () => this.headerRef?.current.focus();
  }
  
  state = {
    filter: '',
    open: true,
    ...common.createButtonClickState()
  };

  static defaultProps = {
    count: 0,
    label: '',
    type: 'general',
    handler: null,
  };

  addTooltip() {
    const toolText = 'Excludes classes whose names start with "Test"; excludes scripts whose names start with "test_" or end with "_test.py"; excludes folders called "tests"; excludes classes, scripts and folders whose names start with a leading underscore';

    return <InfoTooltip popupText={toolText} />;
  }

  render() {
    const textFilteredDiff = this.textFilter();
    const finalDiff = this.buttonFilter(textFilteredDiff);

    return (
      <Fragment>
        <Card onKeyDown={this.onKeyDown}>
          <Card.Header
            className='section-header'
            onClick={this.toggleSectionHandlers.onClick}
            onKeyDown={this.toggleSectionHandlers.onKeyDown}
            tabIndex="0"
            ref={this.headerRef}
          >
            <Card.Title as="h4">Classes {this.addTooltip()}<KeyboardShortcutWidget shortcutKey={this.props.sectionIndex} /><ExpandCardWidget open={this.state.open}/></Card.Title>
          </Card.Header>
          <Collapse in={this.state.open}>
            <Card.Body>
              <SearchBar
                name="Classes"
                searchQuery={this.state.filter}
                setSearchQuery={v => this.setState({ filter: v })}
              />
              <div className="button-row flex-row">
                <FilterButtonGroup
                  buttons={this.buttonGenerator()}
                  data={textFilteredDiff}
                  handler={this.clickHandler}
                />
                <ResultsWidget
                  totalCount={textFilteredDiff.length}
                  filteredCount={finalDiff.length}
                />
              </div>
              <div className="scrollable-content" role="list" ref={this.scrollRef}>
                <ViewportList viewportRef={this.scrollRef} items={finalDiff} overscan={100}>
                  {
                    (d, i) => {
                      const obj = d.earlier || d.later;  
                      return (
                        <PythonClassesEntry
                          key={d.name}
                          functionName={obj.name}
                          moduleName={obj.module}
                          earlier={d.earlier}
                          later={d.later}
                          type={d.type}
                          earlierVersion={this.props.earlierVersion}
                          laterVersion={this.props.laterVersion}
                          open={this.isOpen(d.name)}
                          handler={this.handleOpenAndClose}
                          index={i}
                        />
                      );
                    }
                  }
                </ViewportList>
              </div>
            </Card.Body>
          </Collapse>
        </Card>
      </Fragment >
    );
  }
}


export default ClassesComponent;