/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DiffifyNavbar from 'components/Navbars/DiffifyNavbar';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';

import routes from 'routes.js';

import Diff from 'views/Diff';
import Home from 'views/Home';


class Diffify extends Component {
  render() {
    return (
      <>
        <div className="skiplink"><a href="#main">Skip to main content</a></div>
        <div className="wrapper">
          <Sidebar color={'black'} image={''} routes={routes} />
          <div className="main-panel">
            <DiffifyNavbar />
            <div className="content">
              <Switch>
                <Route exact path="/R/:packageName?/:earlierVersion?/:laterVersion?">
                  <Diff key="R" language="R" />
                </Route>
                <Route exact path="/python/:packageName?/:earlierVersion?/:laterVersion?">
                  <Diff key="python" language="python" />
                </Route>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

export default Diffify;
