import React, { Component, createRef } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { buttonIcons, dropdownIcons } from '../../../../misc/icons';
import { PythonMethodsComponent } from 'components/DiffSection/Sections';
import { createOpenFunctions } from '../common';
import 'assets/css/table.css';


function renderMethodsText() {
  return (
    <div>
      <span>Methods <i className={dropdownIcons.modal}></i></span>
    </div>
  );
}


function renderModal() {
  const instance = this;
  const { currentPackage, earlierVersion, laterVersion, moduleName, functionName, type } = this.props;

  const handleClose = function() {
    instance.setState({ open: false });
  };

  const onKeyDown = function(evt) {
    if (evt.key === 'Escape') {
      handleClose();
      evt.preventDefault();
    }
    else if (evt.shiftKey && evt.key === 'Home') {
      instance.closeModal?.current.focus();
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  return (
    <Modal
      show={this.state.open}
      onKeyDown={onKeyDown}
      onHide={handleClose}
      size={'xl'}
      fullscreen={'true'}
      animation={false}
    >
      <Modal.Header>
        <h3><em>{`${moduleName}.`}</em><wbr/><span style={{fontWeight: 'bold'}}>{functionName}</span></h3>
        <Button
          tabIndex="0"
          className='modal-close'
          onClick={handleClose}
          aria-label="Close modal"
          ref={instance.closeModal}
          autoFocus={this.state.focus}
        ><i className="fas fa-times"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="12">
            <PythonMethodsComponent
              currentPackage={currentPackage}
              earlierVersion={earlierVersion}
              laterVersion={laterVersion}
              moduleName={moduleName}
              functionName={functionName}
              type={type}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}


class ClassesEntry extends Component {
  constructor(props) {
    super(props);
    this.closeModal = createRef();
  }

  state = {
    open: this.props.open
  };

  static defaultProps = {
    open: false,
  };

  render() {
    const { type, moduleName, functionName } = this.props;
    const haveModal = type !== 'removed' && (this.props.earlier?.methods.length || this.props.later?.methods.length);
    const classes = `entry alert flex-row ${haveModal ? 'expandable' : 'unexpandable'}`;
    const { onClick, onKeyDown } = createOpenFunctions(this, haveModal);
    
    return (
      <div
        data-type={type}
        className={classes}
        role="listitem"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <i className={`type-symbol ${buttonIcons[type]}`}></i>
        <div className="entry-name"><span className="module-name">{moduleName}.</span><wbr/><span>{functionName}</span></div>
        { haveModal ? renderMethodsText.call(this) : null }
        { haveModal ? renderModal.call(this) : null }
      </div>
    );
  }
}


export default ClassesEntry;