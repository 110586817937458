import React, { Component } from 'react';
import { RDiffSection, PythonDiffSection } from './Sections';
import 'assets/css/diffsection.css';


class DiffSection extends Component {
  render() {
    const { packageName, earlierVersion, laterVersion, packageVersions, language } = this.props;
    const key = `${packageName}:::${earlierVersion}:::${laterVersion}`;

    if (language === 'R') {
      return (
        <RDiffSection
          key={key}
          earlierVersion={earlierVersion}
          laterVersion={laterVersion}
          packageVersions={packageVersions}
          className={'diff-section'}
        />
      );
    }
    else if (language === 'python')  {
      return (
        <PythonDiffSection
          key={key}
          earlierVersion={earlierVersion}
          laterVersion={laterVersion}
          className={'diff-section'}
        />
      );
    }
    else {
      return null;
    }
  }
}

export default DiffSection;
