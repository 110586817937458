import React, { Component } from 'react';

class ExpandCardWidget extends Component {
  render() {
    return (
      <div className={'expand-card-widget float-right'}>
        <i className={'fas float-right' + (this.props.open ? ' fa-caret-up' : ' fa-caret-down')} />
        <p className={'float-right toggle-text'}>{this.props.open ? 'hide' : 'show'}</p>
      </div>
    );
  }
};

export default ExpandCardWidget;