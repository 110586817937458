import { currentPackage } from '../../../datastore';

const defaultButtons = [
  { id: 'added', 'type': 'added', label: 'Added' },
  { id: 'removed', 'type': 'removed', label: 'Removed' },
  { id: 'changed', 'type': 'changed', label: 'Changed' },
];


function areEqualWrapper(areEqual) {
  return function(ealier, later) {
    try {
      const eString = JSON.stringify(earlier);
      const lString = JSON.stringify(later);
      // If the two stringified objects are the same, character for character,
      // then they must be equal unstringified
      if (eString === lString) { return true; }
    }
    catch(e) {}
    return areEqual(ealier, later);
  };
}


function every(entity, func) {
  return Array.from(entity).every(func);
}

function setsAreEqual(a, b) {
  if (a.size !== b.size) { return false; }
  return every(a, d => b.has(d));
};


function createToggleSectionHandlers(openKey = 'open') {
  const instance = this;

  const onClick = function() {
    instance.setState(prevState => ({ [openKey]: !prevState[openKey] }));
  };

  const onKeyDown = function(evt) {
    if (['Enter', 'Space'].includes(evt.code)) {
      onClick();
      evt.preventDefault();
    }
  };

  return { onClick, onKeyDown };
}


function createButtonClickHandler() {
  const instance = this;

  return function(prop, value) {
    instance.setState({ [`${prop}Clicked`]: value });
  };
}


function createDiffifyer(accessor, areEqual = () => true, getName = d => d.name) {
  const instance = this;
  const equal = areEqualWrapper(areEqual);

  const getData = function(packageVersion) {
    const dataset = currentPackage.version(packageVersion);
    if (!Object.keys(dataset).length) { return []; }
    return typeof accessor === 'string' ? dataset[accessor] : accessor(dataset);
  };

  return function() {
    const { earlierVersion, laterVersion } = instance.props;
    const earlierData = getData(earlierVersion);
    const laterData = getData(laterVersion);
    const earlierMap = new Map(earlierData.map(d => [getName(d), d]));
    const laterMap = new Map(laterData.map(d => [getName(d), d]));

    return Object.freeze(
      Array.from(new Set(earlierData.concat(laterData).map(getName)))
        .map(function(name) {
          const earlier = earlierMap.get(name);
          const later = laterMap.get(name);
          let type = 'unchanged';
          if (!earlier) { type = 'added'; }
          else if (!later) { type = 'removed'; }
          else if (!equal(earlier, later)) { type = 'changed'; }
          return { name, earlier, later, type };
        })
        .filter(d => d.type !== 'unchanged')
        .sort(function(a, b) {
          const aType = a.type;
          const bType = b.type;
          // If types are the same sort lexically
          if (aType === bType) {
            return a.name.localeCompare(b.name);
          }
          // Else sort so that "added" before "removed" before "changed"
          if (aType === 'added') { return -1; }
          if (aType === 'removed') { return bType === 'added' ? 1 : -1; }
          else { return 1; }
        })
    );
  };
} 


function createTextFilter(data, nameProp = 'name', filterProp = 'filter',) {
  const instance = this;
  const cache = { filterText: '', data: data };
  const normalize = t => t.toLowerCase();

  const applyTextFilter = function(filterText) {
    if (!filterText) { cache.data = data; }
    else {
      const startData = filterText.includes(cache.filterText) ? cache.data : data;
      cache.data = Object.freeze(startData.filter(d => normalize(d[nameProp]).includes(filterText)));
    }
    cache.filterText = filterText;
  };

  return function() {
    const filterText = normalize(instance.state[filterProp]);
    if (filterText !== cache.filterText) { applyTextFilter(filterText); }
    return cache.data;
  };
}


function createButtonFilter(buttons = defaultButtons, accessor = d => d.type) {
  const instance = this;
  const buttonsMap = new Map(buttons.map(d => [`${d.id}Clicked`, d.id]));
  
  const cache = { inputData: null, filterSet: null, ouputData: null };

  const getFilterSet = function() {
    return new Set(
      Array.from(buttonsMap)
        .filter(([k]) => !instance.state[k])
        .map(([_, id]) => id)
    );
  };

  const cacheIsValid = function(inputData, filterSet) {
    if (inputData !== cache.inputData) { return false; }
    return setsAreEqual(filterSet, cache.filterSet);
  };

  const applyButtonFilter = function(inputData, filterSet) {
    cache.inputData = inputData;
    cache.filterSet = filterSet;
    const n = filterSet.size;
    if (n === buttons.length) { cache.ouputData = inputData; }
    else if (n === 0) { cache.ouputData = Object.freeze([]); }
    else {
      cache.ouputData = Object.freeze(inputData.filter(d => filterSet.has(accessor(d))));
    }
  };

  return function(data) {
    const filterSet = getFilterSet();
    if (!cacheIsValid(data, filterSet)) { applyButtonFilter(data, filterSet); }
    return cache.ouputData;
  };
}


function createButtonGenerator(buttons = defaultButtons) {
  const instance = this;

  return function() {
    return buttons.map(d => {
      const clicked =  instance.state[`${d.id}Clicked`];
      return Object.assign({clicked}, d);
    });
  };
}


function createButtonClickState(buttons = defaultButtons) {
  return buttons.reduce(function(obj, button) {
    obj[`${button.id}Clicked`] = false;
    return obj;
  }, {});
}


function createOpenAndCloseHandler(openSet) {
  return function(item, value) {
    openSet[value ? 'add' : 'delete'](item);
  };
}


function createIsOpen(openSet) {
  return function(id) {
    return openSet.has(id);
  };
}


function focusOn(offset, header) {
  const index = this.sectionRefs.findIndex(r => header === r?.headerRef?.current) + offset;
  const newFocus = this.sectionRefs[index];
  if (newFocus) { newFocus.focus(); }
  return !!newFocus;
};


function createCreateOnShiftKeyDown() {
  const parentInstance = this;
  const focusOnPrevious = focusOn.bind(parentInstance, -1);
  const focusOnNext = focusOn.bind(parentInstance, 1);

  return function() {
    const instance = this;

    return function(evt) {
      const { key, code, shiftKey } = evt;

      // If shift key is not held down or this is the shify key being pressed we don't care
      if (!shiftKey || key === 'Shift') { return; }
      // If the current focus is on an input element then the shift key needs to be used for typing
      // so we disable the accelerator keys
      if (evt.target.nodeName.toLowerCase() === 'input') { return; }
      // If a modal window is visible we don't want to try to steal focus from it
      if (document.querySelector('[role="dialog"]')) { return; }
  
      const header = instance.headerRef?.current;
      let success = false;
  
      if (key === 'ArrowUp' ) {
        if (evt.target !== header) {
          header.focus();
          success = true;
        }
        else { success = focusOnPrevious(header); }
      }
      else if (key === 'ArrowDown') { success = focusOnNext(header); }
      else if (code.startsWith('Digit')) {
        const index = parseInt(code.replace('Digit', '')) - 1;
        const newFocus = parentInstance.sectionRefs[index]?.headerRef?.current;
        if (newFocus && newFocus !== evt.target) {
          newFocus.focus();
          success = true;
        }
      }
  
      if (success) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    };
  };
};


function createTabKeyDownCapture() {
  const instance = this;

  return function(evt) {
    const { key, shiftKey } = evt;
    if (key === 'Shift') { return; }
    if (!['ArrowUp', 'ArrowDown'].includes(key)) { return; }
    evt.preventDefault();
    evt.stopPropagation();
    if (shiftKey && instance.onKeyDown) {
      instance.onKeyDown(evt);
    }
  };
}


const common = {
  createToggleSectionHandlers,
  createButtonClickHandler,
  createDiffifyer,
  createTextFilter,
  createButtonFilter,
  createButtonGenerator,
  createButtonClickState,
  createOpenAndCloseHandler,
  createIsOpen,
  createCreateOnShiftKeyDown,
  createTabKeyDownCapture,
  defaultButtons,
};


export { common };