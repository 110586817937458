import React, { Component } from 'react';
import { Form } from 'react-bootstrap';


class SearchBar extends Component {
  render() {
    const { name, searchQuery, setSearchQuery } = this.props;

    return (
      <div className="search-bar-container">
        <Form.Control
          value={searchQuery}
          onInput={evt => setSearchQuery(evt.target.value)}
          type="text"
          id={name + '-header-search'}
          placeholder="filter..."
          name={name + '-header-search'} 
          className="search-bar"
        />
      </div>
    );
  }
}


export default SearchBar;