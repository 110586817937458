import React, { Component, Fragment, createRef } from 'react';
import { Card, Collapse } from 'react-bootstrap';
import { Entry } from '../../ListItems';
import { FilterButtonGroup, SearchBar } from '../../Controls';
import { ExpandCardWidget, KeyboardShortcutWidget, ResultsWidget } from '../../Widgets';
import { common } from './../common';


const primaryButtons = common.defaultButtons.filter(d => d.id !== 'changed');

const secondaryButtons = ['export', 'S3method', 'S4method', 'S4Class', 'pattern']
  .map(d => ({ id: d, label: d }));

const secondaryAccessor = d => (d.earlier || d.later).type;


class ExportsComponent extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = common.createButtonClickHandler.call(this);
    const diffify = common.createDiffifyer.call(this, 'exports');
    const diff = diffify();
    this.textFilter = common.createTextFilter.call(this, diff);
    this.buttonFilter = common.createButtonFilter.call(this, primaryButtons);
    this.secondaryButtonFilter = common.createButtonFilter.call(this, secondaryButtons, secondaryAccessor);
    this.buttonGenerator = common.createButtonGenerator.call(this, primaryButtons);
    this.secondaryButtonGenerator = common.createButtonGenerator.call(this, secondaryButtons);
    this.headerRef = createRef();
    this.toggleSectionHandlers = common.createToggleSectionHandlers.call(this);
    this.focus = () => this.headerRef?.current.focus();
    this.onKeyDown = this.props.createOnShiftKeyDown.call(this);
  }


  state = {
    filter: '',
    open: true,
    HeaderHover: false,
    ...common.createButtonClickState(primaryButtons.concat(secondaryButtons))
  };


  render() {
    const textFilteredDiff = this.textFilter();
    const typeFilteredDiff = this.buttonFilter(textFilteredDiff);
    const finalDiff = this.secondaryButtonFilter(typeFilteredDiff);
    
    return (
      <Fragment>
        <Card onKeyDown={this.onKeyDown}>
          <Card.Header
            className='section-header'
            onClick={this.toggleSectionHandlers.onClick}
            onKeyDown={this.toggleSectionHandlers.onKeyDown}
            tabIndex="0"
            ref={this.headerRef}
          >
            <Card.Title as="h4">
              Namespace <KeyboardShortcutWidget shortcutKey={this.props.sectionIndex} /><ExpandCardWidget open={this.state.open}/>
            </Card.Title>
          </Card.Header>
          <Collapse in={this.state.open}>
            <Card.Body>
              <SearchBar
                name="Exports"
                searchQuery={this.state.filter}
                setSearchQuery={v => this.setState({ filter: v })}
              />
              <div className="button-row flex-row">
                <FilterButtonGroup
                  buttons={this.buttonGenerator()}
                  data={textFilteredDiff}
                  handler={this.clickHandler}
                />
                <FilterButtonGroup
                  buttons={this.secondaryButtonGenerator()}
                  data={typeFilteredDiff}
                  handler={this.clickHandler}
                  accessor={secondaryAccessor}
                  hideEmpty={true}
                />
                <ResultsWidget
                  totalCount={textFilteredDiff.length}
                  filteredCount={finalDiff.length}
                />
              </div>
              <div className="scrollable-content" role="list">
                {
                  finalDiff.map((d) => {
                    return (
                      <Entry
                        key={d.name}
                        type={d.type}
                        name={d.name}
                        rightText={d.type === 'added' ? d.later.type : d.earlier.type}
                      />
                    );
                  })
                }
              </div>
            </Card.Body>
          </Collapse>
        </Card>
      </Fragment >
    );
  }
}


export default ExportsComponent;
