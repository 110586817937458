import React, { Component, Fragment, createRef } from 'react';
import { Card, Collapse } from 'react-bootstrap';
import { ViewportList } from 'react-viewport-list';
import InfoTooltip from '../../../InfoTooltip';
import { RFunctionEntry } from '../../ListItems';
import { FilterButtonGroup, SearchBar } from '../../Controls';
import { ExpandCardWidget, KeyboardShortcutWidget, ResultsWidget } from '../../Widgets';
import { common } from './../common';


function areEqual(earlier, later) {
  const eArgs = earlier.arguments;
  const lArgs = later.arguments;

  if (eArgs.length !== lArgs.length) { return false; }

  return eArgs.every(function({name, value}, i) {
    const l = lArgs[i];
    return l.name === name && l.value === value;
  });
}


class FunctionsComponent extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = common.createButtonClickHandler.call(this);
    const diffify = common.createDiffifyer.call(this, 'functions', areEqual);
    const diff = diffify();
    this.textFilter = common.createTextFilter.call(this, diff);
    this.buttonFilter = common.createButtonFilter.call(this);
    this.buttonGenerator = common.createButtonGenerator.call(this);

    const openedSet = new Set();
    this.handleOpenAndClose = common.createOpenAndCloseHandler(openedSet);
    this.isOpen = common.createIsOpen(openedSet);

    this.scrollRef = createRef();
    this.headerRef = createRef();
    this.toggleSectionHandlers = common.createToggleSectionHandlers.call(this);
    this.onKeyDown = this.props.createOnShiftKeyDown.call(this);
    this.focus = () => this.headerRef?.current.focus();
  }
  

  state = {
    filter: '',
    open: true,
    ...common.createButtonClickState()
  };

  addTooltip() {
    const toolText = 'Includes any functions that are documented in the package man/ directory';
    return <InfoTooltip popupText={toolText} />;
  }

  render() {
    const textFilteredDiff = this.textFilter();
    const finalDiff = this.buttonFilter(textFilteredDiff);

    return (
      <Fragment>
        <Card onKeyDown={this.onKeyDown}>
          <Card.Header
            className='section-header'
            onClick={this.toggleSectionHandlers.onClick}
            onKeyDown={this.toggleSectionHandlers.onKeyDown}
            tabIndex="0"
            ref={this.headerRef}
          >
            <Card.Title as="h4">Functions {this.addTooltip()} <KeyboardShortcutWidget shortcutKey={this.props.sectionIndex} /><ExpandCardWidget open={this.state.open}/></Card.Title>
          </Card.Header>
          <Collapse in={this.state.open}>
            <Card.Body>
              <SearchBar
                name="Functions"
                searchQuery={this.state.filter}
                setSearchQuery={v => this.setState({ filter: v })}
              />
              <div className="button-row flex-row">
                <FilterButtonGroup
                  buttons={this.buttonGenerator()}
                  data={textFilteredDiff}
                  handler={this.clickHandler}
                />
                <ResultsWidget
                  totalCount={textFilteredDiff.length}
                  filteredCount={finalDiff.length}
                />
              </div>
              <div className="scrollable-content" role="list" ref={this.scrollRef}>
                <ViewportList viewportRef={this.scrollRef} items={finalDiff} overscan={100}>
                  {
                    d => {
                      return (
                        <RFunctionEntry
                          key={d.name}
                          name={d.name}
                          earlier={d.earlier}
                          later={d.later}
                          type={d.type}
                          earlierVersion={this.props.earlierVersion}
                          laterVersion={this.props.laterVersion}
                          open={this.isOpen(d.name)}
                          handler={this.handleOpenAndClose}
                        />
                      );
                    }
                  }
                </ViewportList>
              </div>
            </Card.Body>
          </Collapse>
        </Card>
      </Fragment >
    );
  }
}


export default FunctionsComponent;