import React, { Component } from 'react';


class InfoTooltip extends Component {
  state = {};

  render() {
    const onClick = evt => evt.stopPropagation();

    return (
      <div className="tooltip-container">
        <div className="tooltip-target" tabIndex="0" onClick={onClick}>
          <i className="fas fa-info-circle"></i>
          <i className="fas fa-arrow-left hover-icon" ></i>
        </div>
        <div className="tooltip-popup">{this.props.popupText}</div>
      </div>
    );
  }
}


export default InfoTooltip;