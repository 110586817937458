import React, { Component } from 'react';
import { buttonIcons } from '../../../misc/icons';
import { packageExists } from '../../../datastore';


function renderPackageLink() {
  const { name, isPackage } = this.props;

  if (!isPackage || !packageExists(name, 'R')) { return null; }

  const onClick = function(evt) {
    evt.stopPropagation();
  };

  const onKeyDown = function(evt) {
    if (['Enter', 'Space'].includes(evt.code)) {
      evt.stopPropagation();
    }
  };

  return (
    <a
      aria-label={name}
      className="linked-package"
      href={`/R/${name}`}
      target="_blank"
      onClick={onClick}
      onKeyDown={onKeyDown}
      rel="noreferrer"
    ><i className="fas fa-link"></i>
    </a>
  );
}


class Entry extends Component {
  state = {};

  render() {
    const type = this.props.type;

    return (
      <div data-type={type} className="entry alert flex-row unexpandable" role="listitem" tabIndex="0">
        <i className={`type-symbol ${buttonIcons[type]}`}></i>
        <div className="entry-name"><span>{this.props.name}</span>{renderPackageLink.call(this)}</div>
        <div>
          <span>{this.props.rightText || ''}</span>
        </div>
      </div>
    );
  }
}


export default Entry;


