import { Badge } from 'react-bootstrap';
import React, { Component } from 'react';


function constructCountText() {
  const { totalCount, filteredCount } = this.props;
  
  if (filteredCount === totalCount) {
    return `${totalCount} result${totalCount === 1 ? '' : 's'}`;
  }
  return `${filteredCount} of ${totalCount} result${this.props.totalCount === 1 ? '' : 's'}`;
}


class ResultsWidget extends Component {
  state = {};

  render() {
    return (
      <div className="filtertext">
        {
          <p>
            <Badge variant="light">{constructCountText.call(this)}</Badge>
          </p>
        }
      </div>
    );
  }
}


export default ResultsWidget;