import React, { Component } from 'react';
import { PythonDependencyEntry } from '../../ListItems';
import { FilterButtonGroup, SearchBar } from '../../Controls';
import ResultsWidget from '../../Widgets/ResultsWidget';
import { common } from '../common';


function getSortedSpecifiers(arr) {
  return arr.specifiers.slice()
    .sort((a, b) => a.version.localeCompare(b.version));
}


function areEqual(e, l) {
  if (e.specifiers.length !== l.specifiers.length) { return false; }

  const eSpec = getSortedSpecifiers(e);
  const lSpec = getSortedSpecifiers(l);
  
  return eSpec.every(function({version, marker}, i) {
    return lSpec[i].version === version && lSpec[i].marker === marker;
  });
}


class DependenciesComponent extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = common.createButtonClickHandler.call(this);
    const diffify = common.createDiffifyer.call(this, this.props.property, areEqual);
    const diff = diffify();
    this.textFilter = common.createTextFilter.call(this, diff);
    this.buttonFilter = common.createButtonFilter.call(this);
    this.buttonGenerator = common.createButtonGenerator.call(this);

    const openedSet = new Set();
    this.handleOpenAndClose = common.createOpenAndCloseHandler(openedSet);
    this.isOpen = common.createIsOpen(openedSet);
  }
  

  state = {
    filter: '',
    open: true,
    ...common.createButtonClickState()
  };


  render() {
    const textFilteredDiff = this.textFilter();
    const finalDiff = this.buttonFilter(textFilteredDiff);

    return (
      <div className="margin-top-vh">
        <SearchBar
          name={this.props.name}
          searchQuery={this.state.filter}
          setSearchQuery={v => this.setState({ filter: v })}
        />
        <div className="button-row flex-row">
          <FilterButtonGroup
            buttons={this.buttonGenerator()}
            data={textFilteredDiff}
            handler={this.clickHandler}
          />
          <ResultsWidget
            totalCount={textFilteredDiff.length}
            filteredCount={finalDiff.length}
          />
        </div>
        <div className="scrollable-content" role="list">
          {
            finalDiff.map((d) => {
              return (
                <PythonDependencyEntry
                  key={d.name}
                  name={d.name}
                  earlier={d.earlier}
                  later={d.later}
                  type={d.type}
                  earlierVersion={this.props.earlierVersion}
                  laterVersion={this.props.laterVersion}
                  open={this.isOpen(d.name)}
                  handler={this.handleOpenAndClose}
                />
              );
            })
          }
        </div>

      </div>
    );
  }
}


export default DependenciesComponent;
