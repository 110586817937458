function createToggleFunctions(instance, isTogglable) {
  if (!isTogglable) {
    return { onClick: null, onKeyDown: null };
  }

  const onClick = function() {
    const open = !instance.state.open;
    instance.setState({ open });
    const handler = instance.props.handler;
    if (handler) { handler(instance.props.name, open); }
  };

  const onKeyDown = function(evt) {
    if (['Enter', 'Space'].includes(evt.code)) {
      onClick();
      evt.preventDefault();
    }
  };

  return { onClick, onKeyDown };
}


function createOpenFunctions(instance, isOpenable) {
  if (!isOpenable) {
    return { onClick: null, onKeyDown: null };
  }

  const open = function (focus = false) {
    if (instance.state.open) { return; }
    const open = true;
    instance.setState({ open, focus });
  };

  const onClick = function() {
    open();
  };

  const onKeyDown = function(evt) {
    if (instance.state.open) { return; }
    if (['Enter', 'Space'].includes(evt.code)) {
      open(true);
      evt.preventDefault();
    }
  };

  return { onClick, onKeyDown };
}


export { createToggleFunctions, createOpenFunctions };