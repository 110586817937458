import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import DependenciesContainer from './DependenciesContainer';
import ExportsComponent from './ExportsComponent';
import FunctionsComponent from './FunctionsComponent';
import NewsComponent from './NewsComponent';
import { common } from '../common'; 


class DiffSection extends Component {
  constructor(props) {
    super(props);
    this.sectionRefs = [];
    this.createOnShiftKeyDown = common.createCreateOnShiftKeyDown.call(this);
  }

  render() {
    const { earlierVersion, laterVersion, currentPackage, packageVersions } = this.props;
    const addSectionRef = ref => this.sectionRefs.push(ref);

    return (
      <Row className="diff-section" onKeyDown={this.onKeyDown}>
        <Col md="12">
          <NewsComponent
            currentPackage={currentPackage}
            earlierVersion={earlierVersion}
            laterVersion={laterVersion}
            packageVersions={packageVersions}
            ref={addSectionRef}
            createOnShiftKeyDown={this.createOnShiftKeyDown}
            sectionIndex={1}
          />
        </Col>
        <Col md="12">
          <DependenciesContainer
            currentPackage={currentPackage}
            earlierVersion={earlierVersion}
            laterVersion={laterVersion}
            ref={addSectionRef}
            createOnShiftKeyDown={this.createOnShiftKeyDown}
            sectionIndex={2}
          />
        </Col>
        <Col md="12">
          <ExportsComponent
            currentPackage={currentPackage}
            earlierVersion={earlierVersion}
            laterVersion={laterVersion}
            ref={addSectionRef}
            createOnShiftKeyDown={this.createOnShiftKeyDown}
            sectionIndex={3}
          />
        </Col>
        <Col md="12">
          <FunctionsComponent
            currentPackage={currentPackage}
            earlierVersion={earlierVersion}
            laterVersion={laterVersion}
            ref={addSectionRef}
            createOnShiftKeyDown={this.createOnShiftKeyDown}
            sectionIndex={4}
          />
        </Col>
      </Row>
    );
  }
}

export default DiffSection;
