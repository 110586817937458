import { Card, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import Autocomplete from 'components/Autocomplete';
import React, { Component } from 'react';
import DiffSection from 'components/DiffSection/DiffSection';
import { RecentPackagesSection } from 'components/DiffSection/Sections';
import { withRouter } from 'react-router-dom';
import {
  packageExists,
  getCanonicalPackageName,
  getPackageNames,
  setCurrentPackage,
  currentPackage,
  getTimestamp,
  revalidateCache
} from '../datastore';
import { languageIcons } from 'misc/icons';
import { createGetBadge } from 'misc/badge';
import InfoTooltip from '../components/InfoTooltip';


function getPackageName(pckgName = this.urlParams.packageName) {
  const packageName = getCanonicalPackageName(pckgName, this.props.language);
  if (!packageName) { return null; }

  if (packageName !== pckgName) {
    const { earlierVersion, laterVersion } = this.urlParams;
    this.redirectTo(packageName, earlierVersion, laterVersion);
  }

  return packageName;
}


function navigateTo(pathComponents, redirect) {
  const path = `/${this.props.language}/${pathComponents.join('/')}`;
  if (path !== this.props.match.url) {
    this.props.history[redirect ? 'replace' : 'push'](path);
  }
  const [packageName, earlierVersion, laterVersion] = pathComponents;
  this.setState({ packageName, earlierVersion, laterVersion });
}


function versionToOption(version) {
  return { label: version === 'empty' ? 'Empty package' : version, value: version };
}


async function handleEarlierVersionChange(event) {
  const earlierVersion = event.value;
  this.setState({ isLoading: true });
  await currentPackage.loadVersion(earlierVersion);
  this.setState({ isLoading: false });
  const { laterVersion } = this.urlParams;
  this.goTo(currentPackage.name, earlierVersion, laterVersion);
};


async function handleLaterVersionChange(event) {
  const packageVersions = currentPackage.packageVersions;
  const laterVersion = event.value;
  const laterIndex = packageVersions.indexOf(laterVersion);
  let { earlierVersion } = this.urlParams;
  // Make sure earlierVersion is always earlier package version than laterVersion
  if (laterIndex >= packageVersions.indexOf(earlierVersion)) {
    earlierVersion = packageVersions[laterIndex + 1];
  }
  this.setState({ isLoading: true });
  await Promise.all([earlierVersion, laterVersion].map(d => currentPackage.loadVersion(d)));
  this.setState({ isLoading: false });
  this.goTo(currentPackage.name, earlierVersion, laterVersion);
};


async function handlePackageLoad(packageName, userNavigated) {
  this.setState({ isLoading: true });
  await setCurrentPackage(packageName, this.props.language);

  const packageVersions = currentPackage.packageVersions;
  this.setState({ packageVersions });

  const n = packageVersions.length;
  const defaultEarlier = packageVersions[1];
  const defaultLater = packageVersions[0];

  const { earlierVersion, laterVersion } = this.urlParams;

  let earlier, later;
  if (userNavigated) {
    earlier = earlierVersion && packageVersions.includes(earlierVersion) ? earlierVersion : defaultEarlier;
    later = laterVersion && packageVersions.includes(laterVersion) ? laterVersion : defaultLater;
    // We need to check and refine earlier and later if they don't make sense
    // But there's not much that can be done if a package only has one version
    if (n > 1) {
      const earlierIndex = packageVersions.indexOf(earlier);
      const laterIndex = packageVersions.indexOf(later);
      // If earlierIndex is a later package, swap them
      if (earlierIndex < laterIndex) {
        [earlier, later] = [later, earlier];
      }
      // If the indexes are the same we need to change one
      else if (earlierIndex === laterIndex) {
        // If that index is the earliest version available, make the later version one version later
        if (laterIndex === (n - 1)) { later = packageVersions[laterIndex - 1]; }
        // Otherwise make the earlier version one package earlier
        else { earlier = packageVersions[earlierIndex + 1]; }
      }
    }
  }
  else {
    earlier = defaultEarlier;
    later = defaultLater;
  }

  await Promise.all([earlier, later].map(v => currentPackage.loadVersion(v)));

  this[userNavigated ? 'redirectTo' : 'goTo'](packageName, earlier, later);

  this.setState({ isLoading: false });
};


async function handlePackageChange(pckgName) {
  const language = this.props.language;
  await revalidateCache(language);

  const packageName = getPackageName.call(this, pckgName);

  if (packageName) {
    handlePackageLoad.call(this, packageName);
  }
  else {
    this.goTo(packageName);
  }
}


function handleHistoryButtonClick() {
  const packageName = getPackageName.call(this);
  if (packageName) { handlePackageLoad.call(this, packageName, true); }
};


function showPackageNotFound() {
  const { packageName } = this.urlParams;
  const style = { color: 'red' };
  return (
    <p style={style}>{`Package "${packageName}" not found`}</p>
  );
}


function addTimestampInfo() {
  const { date, time } = getTimestamp(this.props.language);

  let toolText;
  if (this.props.language === 'R') {
    toolText = `Package list last updated at ${time} on ${date} (UTC).`;
  }
  else {
    const numPypiPackages = 5000;
    toolText = `Package list includes the ${numPypiPackages} most downloaded PyPI packages. Last updated on ${date} at ${time} UTC.`;
  }

  return <InfoTooltip popupText={toolText} />;
}


function addEmptyPackageInfo() {
  const toolText = 'The "Empty package" option allows you to compare the selected Later version against an empty package in order to see all dependencies, namespaces and functions associated with the Later version.';
  return <InfoTooltip popupText={toolText} />;
}


function drawLoadingStatus() {
  return (
    <Card role="alert" aria-busy="true">
      <Card.Body style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Loading…</span>
        <Spinner animation="border" size="sm" />
      </Card.Body>
    </Card>
  );
}


class Diff extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      packageNames: null,
      packageName: null,
      earlierVersion: null,
      laterVersion: null
    };

    this.handleEarlierVersionChange = handleEarlierVersionChange.bind(this);
    this.handleLaterVersionChange = handleLaterVersionChange.bind(this);
    this.handlePackageChange = handlePackageChange.bind(this);
    this.handleHistoryButtonClick = handleHistoryButtonClick.bind(this);
  }

  get urlParams() {
    return this.props.match.params;
  }

  goTo(...pathComponents) {
    navigateTo.call(this, pathComponents);
  }

  redirectTo(...pathComponents) {
    navigateTo.call(this, pathComponents, true);
  }


  async componentDidMount() {
    const language = this.props.language;
    await revalidateCache(language);
    const packageName = getPackageName.call(this);

    if (packageName && packageExists(packageName, language)) {
      await handlePackageLoad.call(this, packageName, true);
    }
    else {
      this.setState({ packageName });
    }

    this.setState({
      isLoading: false,
      packageNames: getPackageNames(language),
    });

    window.addEventListener('popstate', this.handleHistoryButtonClick);
    window.addEventListener('pushstate', this.handleHistoryButtonClick);
  }


  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleHistoryButtonClick);
    window.removeEventListener('pushstate', this.handleHistoryButtonClick);
  }


  render() {
    const { packageName, packageNames, isLoading, earlierVersion, laterVersion } = this.state;
    const language = this.props.language;

    if (!packageNames) {
      return (
        <main id="main" style={isLoading ? { cursor: 'wait' } : {}}>
          <div className='select-and-diff' style={isLoading ? { pointerEvents: 'none' } : {}}>
            <Col className='package-selection'>
              <div className='package-selection-wrapper'>
                {drawLoadingStatus()}
              </div>
            </Col>
            <Col className='diff-section-wrapper'></Col>
          </div>
        </main>
      );
    }

    const packageVersions = currentPackage?.packageVersions;

    const laterPackageVersions = packageVersions?.slice(0, packageVersions.length - 1);
    const sliceStartIndex = packageVersions?.indexOf(laterVersion) + 1;
    const EarlierPackageVersions = packageVersions?.slice(sliceStartIndex);
    const EarlierPlaceholder = versionToOption(earlierVersion);
    const LaterPlaceholder = versionToOption(laterVersion);

    let packageNotFound = false;
    if (!packageName && this.urlParams.packageName) {
      packageNotFound = this.urlParams.packageName;
    }

    const getBadge = createGetBadge(packageName, language);

    return (
      <main id="main" style={isLoading ? { cursor: 'wait' } : {}}>
        <div className='select-and-diff' style={isLoading ? { pointerEvents: 'none' } : {}}>
          <Col className='package-selection'>
            <div className='package-selection-wrapper'>
              <Row>
                <Col md="12">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4"><i className={`${languageIcons[this.props.language]} fa-fw`}></i> Package {addTimestampInfo.call(this)}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      {packageNotFound ? showPackageNotFound.call(this) : null}
                      <Autocomplete
                        key={packageName}
                        placeholder={packageName}
                        suggestions={packageNames}
                        callback={this.handlePackageChange}
                      />
                      {<p style={{ fontSize: '0.75em' }}> </p>}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                {packageName && !packageNotFound && packageVersions.length ? <Col md="12">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Later version</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Select
                        aria-label={'Later version'}
                        value={LaterPlaceholder}
                        placeholder={'select'}
                        options={laterPackageVersions.map(versionToOption)}
                        onChange={this.handleLaterVersionChange}
                        className="select"
                      />
                    </Card.Body>
                    <Card.Header>
                      <Card.Title as="h4">Earlier version {addEmptyPackageInfo()}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Select
                        aria-label={'Earlier version'}
                        value={EarlierPlaceholder}
                        placeholder={'select'}
                        options={EarlierPackageVersions.map(versionToOption)}
                        onChange={this.handleEarlierVersionChange}
                        className="select"
                      />
                    </Card.Body>
                    <Card.Body>
                      <details style={{ fontSize: '0.9rem' }}>
                        <summary>Do you maintain this package?</summary>
                        <ul>
                          <li><a href="https://github.com/jumpingrivers/diffify/issues" target='_blank' rel="noreferrer">Raise an issue</a></li>
                          <li><a href="#" download={'badge.svg'} onClick={getBadge} rel="noreferrer">Get a badge</a></li>
                        </ul>
                      </details>
                    </Card.Body>
                  </Card>
                </Col> : null}
              </Row>
              {isLoading ? drawLoadingStatus() : null}
            </div>
          </Col>
          <Col className='diff-section-wrapper'>
            {
              (earlierVersion && laterVersion && packageVersions?.length && !packageNotFound)
                ? <DiffSection
                  language={language}
                  packageName={packageName}
                  earlierVersion={earlierVersion}
                  laterVersion={laterVersion}
                  packageVersions={packageVersions.map(versionToOption)}
                />
                : <RecentPackagesSection
                  language={language}
                />
            }
          </Col>
        </div>
      </main>
    );
  }
}

export default withRouter(Diff);
