import React, { Component } from 'react';
import { Card, Row, Col, Collapse } from 'react-bootstrap';
import { ViewportList } from 'react-viewport-list';
import 'assets/css/diffsection.css';
import { getRecentPackagesList } from 'datastore';
import RecentPackageEntry from './RecentPackageEntry';
import { SearchBar } from '../../Controls';
import { ResultsWidget } from '../../Widgets';
import { common } from '../common';
import { getDatetimeParts } from 'misc/utils';
import InfoTooltip from '../../../InfoTooltip';
import { ExpandCardWidget } from '../../Widgets';


class RecentPackagesSection extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    const data = getRecentPackagesList(this.props.language);
    const n = data.length;
    const earliest = n ? getDatetimeParts(data[n-1].date) : null;
    const latest = n ? getDatetimeParts(data[0].date) : null;
    
    if (n) {
      this.toolText = `Every package released or updated between ${earliest.time} on ${earliest.date} and ${latest.time} on ${latest.date} (UTC).`;
    }
    else {
      this.toolText = 'Cannot find any recent packages';
    }

    this.n = n;

    this.textFilter = common.createTextFilter.call(this, data, 'package');

    this.toggleSectionHandlers = common.createToggleSectionHandlers.call(this);
  }

  state = {
    filter: '',
    open: true
  };

  render() {
    const language = this.props.language;
    const filteredData = this.textFilter();

    return (
      <Row key={language}>
        <Col md="12">
          <Card>
            <Card.Header
              className="section-header"
              onClick={this.toggleSectionHandlers.onClick}
              onKeyDown={this.toggleSectionHandlers.onKeyDown}
              tabIndex="0"
            >
              <Card.Title as="h4">Latest <span className="optional">Releases and Updates </span><InfoTooltip popupText={this.toolText} /><ExpandCardWidget open={this.state.open}/></Card.Title>
            </Card.Header>
            <Collapse in={this.state.open}>
              <Card.Body>
                <SearchBar
                  name="Recent"
                  searchQuery={this.state.filter}
                  setSearchQuery={v => this.setState({ filter: v })}
                />
                <ResultsWidget
                  totalCount={this.n}
                  filteredCount={filteredData.length}
                />
                <div className="scrollable-content" role="list" ref={this.scrollRef}>
                  <ViewportList viewportRef={this.scrollRef} items={filteredData} overscan={100}>
                    {
                      d => {
                        return (
                          <RecentPackageEntry
                            key={`${d.package}-${d.version}`}
                            name={d.package}
                            language={language}
                            version={d.version}
                          />
                        );
                      }
                    }
                  </ViewportList>
                </div>
              </Card.Body>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }
}


export default RecentPackagesSection;
