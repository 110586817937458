import { Collapse } from 'react-bootstrap';
import React, { Component } from 'react';
import { buttonIcons, dropdownIcons } from '../../../../misc/icons';
import { createToggleFunctions } from '../common';
import 'assets/css/table.css';


function renderAddedDropdown() {
  const later = this.props.later;

  return (
    <div>
      <table className='dropdown-table'>
        <thead>
          <tr>
            <th></th>
            <th className='left-boundary'>Name</th>
            <th>Default value</th>
          </tr>
        </thead>
        <tbody>
          {
            later.arguments
              .map((arg, i) => {
                return (
                  <tr key={i}>
                    <th>{i+1}</th>
                    <td className='left-boundary'>{arg.name}</td>
                    <td>{arg.value}</td>
                  </tr>
                );
              })
          }
        </tbody>
      </table>
    </div>
  );
}


function renderChangedDropdown() {
  const { earlier, later, earlierVersion, laterVersion } = this.props;
  
  const eArgs = earlier.arguments;
  const lArgs = later.arguments;
  const n = Math.max(eArgs.length, lArgs.length);
  const combinedArgs = [];

  for (let i = 0; i < n; i++) {
    const earlierName = eArgs[i]?.name || '';
    const earlierValue = eArgs[i]?.value || '';
    const laterName = lArgs[i]?.name || '';
    const laterValue = lArgs[i]?.value || '';
    combinedArgs.push({ earlierName, earlierValue, laterName, laterValue });
  }

  return (
    <div>
      <table className='dropdown-table'>
        <thead>
          <tr>
            <th></th>
            <th colSpan="2" className='left-boundary'>{`Version ${earlierVersion}`}</th>
            <th colSpan="2" className='left-boundary'>{`Version ${laterVersion}`}</th>
          </tr>
          <tr>
            <th></th>
            <th className='left-boundary'>Name</th>
            <th>Default value</th>
            <th className='left-boundary'>Name</th>
            <th>Default value</th>
          </tr>
        </thead>
        <tbody>
          {
            combinedArgs
              .map(({ earlierName, earlierValue, laterName, laterValue }, i) => {
                const nameClass = laterName !== earlierName ? 'changed' : '';
                const defaultClass = laterValue !== earlierValue ? 'changed' : '';
                return (
                  <tr key={i}>
                    <th>{i+1}</th>
                    <td className="left-boundary"><span className={nameClass}>{earlierName}</span></td>
                    <td><span className={defaultClass}>{earlierValue}</span></td>
                    <td className="left-boundary"><span className={nameClass}>{laterName}</span></td>
                    <td><span className={defaultClass}>{laterValue}</span></td>
                  </tr>
                );
              })
          }
        </tbody>
      </table>
    </div>
  );
}


function renderArgumentsText() {
  const { earlier, later, type } = this.props;

  let n;
  if (type === 'added') {
    n = later.arguments.length;
  }
  else if (type === 'changed') {
    n = Math.max(earlier?.arguments.length, later?.arguments.length);
  }

  if (!n) {
    return (
      <div>
        <span> No arguments <i className={dropdownIcons.na} /></span>
      </div>
    );
  }

  const icon = this.state.open ? dropdownIcons.open : dropdownIcons.closed;

  return (
    <div>
      <span> Arguments <i className={icon} /></span>
    </div>
  );
}


function renderCollapse() {
  const { open } = this.state;

  const renderDropdown = this.props.type === 'added' ? renderAddedDropdown : renderChangedDropdown;

  return (
    <div style={{width: '100%'}}>
      <Collapse in={open} style={{overflow: 'auto'}}>
        { renderDropdown.call(this) }
      </Collapse>
    </div>
  );
}


class FunctionEntry extends Component {
  state = {
    open: this.props.open
  };

  static defaultProps = {
    open: false,
  };

  render() {
    const { type, earlier, later, name } = this.props;
    const haveArgumentsLabel = type !== 'removed';
    const haveDropdown = haveArgumentsLabel && (earlier?.arguments.length || later?.arguments.length);
    const classes = `entry alert flex-row ${haveDropdown ? 'expandable' : 'unexpandable'}`;
    const { onClick, onKeyDown } = createToggleFunctions(this, haveDropdown);

    return (
      <div
        data-type={type}
        className={classes}
        role="listitem"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <i className={`type-symbol ${buttonIcons[type]}`}></i>
        <div className="entry-name"><span>{name}</span></div>
        { haveArgumentsLabel ? renderArgumentsText.call(this) : null }
        { haveDropdown ? renderCollapse.call(this) : null }
      </div>
    );
  }
}


export default FunctionEntry;