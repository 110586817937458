import React, { Component, Fragment, createRef } from 'react';
import { Card, Collapse, Tab, Tabs } from 'react-bootstrap';
import { ExpandCardWidget, KeyboardShortcutWidget } from '../../Widgets';
import DependenciesComponent from './DependenciesComponent';
import { common } from '../common';
import { currentPackage } from '../../../../datastore';


class DependenciesContainer extends Component {
  constructor(props) {
    super(props);
    this.headerRef = createRef();
    this.toggleSectionHandlers = common.createToggleSectionHandlers.call(this);
    this.onKeyDown = this.props.createOnShiftKeyDown.call(this);
    this.onKeyDownCaptureTabs = common.createTabKeyDownCapture.call(this);
    this.focus = () => this.headerRef?.current.focus();
  }

  state = {
    open: true,
    HeaderHover: false
  };

  render() {
    const { earlierVersion, laterVersion, sectionIndex } = this.props;

    return (
      <Fragment key={earlierVersion + laterVersion}>
        <Card onKeyDown={this.onKeyDown}>
          <Card.Header
            className='section-header'
            onClick={this.toggleSectionHandlers.onClick}
            onKeyDown={this.toggleSectionHandlers.onKeyDown}
            tabIndex="0"
            ref={this.headerRef}
          >
            <Card.Title as="h4">Dependencies <KeyboardShortcutWidget shortcutKey={sectionIndex} /><ExpandCardWidget open={this.state.open} /></Card.Title>
          </Card.Header>
          <Collapse in={this.state.open}>
            <Card.Body>
              <Tabs defaultActiveKey="depends" transition={false} onKeyDownCapture={this.onKeyDownCaptureTabs}>
                <Tab key="Depends" eventKey="depends" title="Depends">
                  <DependenciesComponent
                    currentPackage={currentPackage}
                    earlierVersion={earlierVersion}
                    laterVersion={laterVersion}
                    name={'Depends'}
                    property={'depends'}
                  />
                </Tab>
                <Tab key="Imports" eventKey="imports" title="Imports">
                  <DependenciesComponent
                    currentPackage={currentPackage}
                    earlierVersion={earlierVersion}
                    laterVersion={laterVersion}
                    name={'Imports'}
                    property={'imports'}
                  />
                </Tab>
                <Tab key="Suggests" eventKey="suggests" title="Suggests">
                  <DependenciesComponent
                    currentPackage={currentPackage}
                    earlierVersion={earlierVersion}
                    laterVersion={laterVersion}
                    name={'Suggests'}
                    property={'suggests'}
                  />
                </Tab>
                <Tab key="Enhances" eventKey="enhances" title="Enhances">
                  <DependenciesComponent
                    currentPackage={currentPackage}
                    earlierVersion={earlierVersion}
                    laterVersion={laterVersion}
                    name={'Enhances'}
                    property={'enhances'}
                  />
                </Tab>
              </Tabs>
            </Card.Body>
          </Collapse>
        </Card>
      </Fragment >
    );
  }
}


export default DependenciesContainer;
