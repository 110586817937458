import React, { Component } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { buttonIcons } from '../../../misc/icons';


function clickHandler() {
  const handler = this.props.handler;
  if (handler) { handler(!this.props.clicked); };
};


function addIcon () {
  const icon = buttonIcons[this.props.type];
  if (!icon) { return <span> </span>; }
  
  return (
    <span> <i className={icon}></i> </span>
  );
}


class FilterButton extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = clickHandler.bind(this);
  }

  static defaultProps = {
    clicked: false,
    count: 0,
    label: '',
    type: 'general',
    handler: null,
    hide: false
  };


  render() {
    const { count, hide } = this.props;
    
    if (hide) { return null; }

    return (
      <Button
        onClick={this.clickHandler}
        variant={this.props.type}
        active={!this.props.clicked}
      ><span>{this.props.label}</span><br/>{addIcon.call(this)}<br/><Badge variant="light">{count}</Badge>
      </Button>
    );
  };
}


export default FilterButton;