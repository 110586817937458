import React, { Component } from 'react';


class RecentPackageEntry extends Component {
  state = {};

  render() {
    const { name, language, version } = this.props;

    return (
      <a role="listitem" className="entry alert flex-row unexpandable recent-package" href={`/${language}/${name}`} rel="noreferrer">
        <div className="entry-name">{name}</div>
        <div>{version}</div>
      </a>
    );
  }
}


export default RecentPackageEntry;


