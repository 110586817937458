/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import Select from 'react-select';


const storage = window.localStorage;

const themes = [
  { label: 'Theme: Default', value: 'default' },
  { label: 'Theme: Dichromat friendly', value: 'cvd' },
  { label: 'Theme: Dark', value: 'dark' }
];


function setTheme(theme) {
  document.body.dataset.theme = theme;
  storage.setItem('theme', theme);
}


function getDefaultTheme() {
  return themes[0].value;
}


function handleThemeChange({value}) {
  setTheme(value);
}


class Header extends React.Component {
  state = {
    open: false
  };

  render() {
    const savedTheme =  storage.getItem('theme');
    const theme = savedTheme && themes.find(d => d.value === savedTheme) ? savedTheme : getDefaultTheme();
    const themeIndex = themes.findIndex(d => d.value === theme);
    setTheme(theme);

    return (
      <Navbar expand="lg">
        <Container fluid>
          <a href="https://jumpingrivers.com" aria-label="Jumping Rivers website" target="_blank" rel="noreferrer">
            <img id="main-logo" src="/jumping-rivers-logo.svg" alt="" style={{display: 'block'}}/>
          </a>
          <div id="theme-selector" style={{width: '300px', maxWidth: '100%', zIndex: this.state.open ? 100 : null}}>
            <Select
              aria-label="Theme"
              options={themes}
              defaultValue={themes[themeIndex]}
              onChange={handleThemeChange}
              onMenuOpen={() => this.setState({open: true})}
              onMenuClose={() => this.setState({open: false})}
              className="select"
            />
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
