const languageIcons = Object.freeze({
  R: 'fab fa-r-project',
  python: 'fab fa-python'
});


const buttonIcons = Object.freeze({
  added: 'fas fa-plus',
  removed: 'fas fa-minus',
  changed: 'fas fa-exchange-alt'
});


const dropdownIcons = Object.freeze({
  open: 'fas fa-chevron-up',
  closed: 'fas fa-chevron-down',
  na: 'fas fa-ban',
  modal: 'fas fa-window-restore'
});


export { languageIcons, buttonIcons, dropdownIcons };