/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';


class Sidebar extends React.Component {
  render() {
    const { color, image, routes } = this.props;
    return (
      <div className="sidebar" data-image={image} data-color={color}>
        <div className="sidebar-wrapper">
          <div className="logo d-flex align-items-center justify-content-start">
            <a href="/" tabIndex="-1" aria-hidden="true"><img src="/diffify_logo.svg" width="100%" alt="" /></a>
          </div>
          <Nav>
            <ul style={{listStyle:'none', paddingLeft:0}}>
              {
                routes.map((prop, key) => {
                  if (!prop.redirect) {
                    return (
                      <li
                        className={prop.active ? 'active' : ''}
                        key={key}
                        style={{cursor: prop.active ? 'default' : 'pointer'}}
                      >
                        <NavLink
                          aria-label={prop.name}
                          to={prop.to}
                          className="nav-link"
                          activeClassName="active"
                          style={{cursor: 'inherit'}}
                        >
                          <div><i className={prop.icon} /><p>{prop.name}</p></div>
                        </NavLink>
                      </li>
                    );
                  }
                  return null;
                })
              }
            </ul>
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
