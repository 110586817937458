import React from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { languageIcons } from 'misc/icons';
import 'assets/css/home.css';

class Home extends React.Component {
  render() {
    return (
      <main id="main" className="home">
        <h1>Welcome to <span className='diffify-remove'>diff</span><span className='diffify-add'>ify</span>!</h1>
        
        <p>Diffify provides you with a comparison between different versions of R packages stored on <a href="https://cran.r-project.org/" target='_blank'rel="noreferrer">CRAN</a> and Python packages stored on <a href="https://pypi.org/" target='_blank'rel="noreferrer">PyPI</a>.</p>
        
        <p>Say you were using a particular version of a package in a project and now a new version is available. With diffify you are easily able to check what has been changed in the new release. In particular, diffify will provide you with information from the NEWS file as well as changes in the dependencies, namespace and functions of the package.</p>

        <div className='button-container'>
          <Button onClick={() => this.props.history.push('/R')}><i className={languageIcons.R}></i> Get started with R</Button>
          <Button onClick={() => this.props.history.push('/python')}><i className={languageIcons.python}></i> Get started with Python</Button>
        </div>

        <ul className="link-list">
          <li><a href="https://github.com/jumpingrivers/diffify/issues" target='_blank'rel="noreferrer">Report an issue</a></li>
          <li><a href="https://www.jumpingrivers.com/tags/diffify/" target='_blank'>Read our latest blog posts</a></li>
        </ul>

        <ul className="new-list">
          <li>
            <details>
              <summary>New: view the latest releases and updates</summary>
              <p>The most recently updated packages are listed on the <a href="/R" target="_blank">R</a> and <a href="/python" target="_blank">Python</a> base pages under "Latest Releases and Updates". Click on any package entry to open the package page showing the latest changes.</p>
            </details>
          </li>
          <li>
            <details>
              <summary>New: dark mode</summary>
              <ol>
                <li>Click the theme dropdown near the top of every page</li>
                <li>Select "Theme: Dark"</li>
              </ol>
            </details></li>
          <li>
            <details>
              <summary>New: get a diffify badge for your package repository</summary>
              <ol>
                <li>Locate your <a href="/R" target="_blank">R</a> or <a href="/python" target="_blank">Python</a> package</li>
                <li>Click on "Do you maintain this package?"</li>
                <li>Click "Get a badge", which will copy an HTML snippet to your clipboard</li>
                <li>Paste the snippet in an HTML or Markdown file</li>
              </ol>
            </details>
          </li>
        </ul>
        
      </main>
    );
  }
}

export default withRouter(Home);